import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';
import { Button } from 'gatsby-material-ui-components';
import React from 'react';

import Layout from '@gaia/Layout';
import SEO from '../components/seo';

import catIllustration from '../images/cat_illustration.svg';


const NotFound = () => (
    <Layout>
        <SEO title="404: Not found" />
        <Stack
            flexDirection="column"
            gap={4}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: { xs: 5, sm: 0 }, mb: { xs: 5, sm: 6 } }}
        >
            <Box>
                <img
                    src={catIllustration}
                    alt="404 image with cute cat drawing."
                    style={{ maxWidth: '100%' }}
                />
            </Box>
            <Box sx={{ maxWidth: 564, textAlign: 'center' }}>
                <Typography component="h1" variant="h2">
                    It’s not cute when a page is missing
                </Typography>
            </Box>
            <Box sx={{ maxWidth: 564, textAlign: 'center' }}>
                <Typography variant="subtitle1">
                    If you would like to share a broken link or give other feedback to us, please{' '}
                    <Link
                        sx={{ color: 'primary.main' }}
                        to="/contact/"
                        aria-label="Contact us page link"
                    >
                        let us know
                    </Link>
                    .
                </Typography>
            </Box>
            <Box>
                <Button to="/" variant="contained" color="primary" size="large">
                    BACK TO HOMEPAGE
                </Button>
            </Box>
        </Stack>
    </Layout>
);

export default NotFound;
